<template>
  <LoaderBlock v-if="loader"></LoaderBlock>
  <AnimationsBlock></AnimationsBlock>
  <HeadMenu></HeadMenu>
  <MainBlocks></MainBlocks>
  <FooterBlock></FooterBlock>
</template>

<script>
import HeadMenu from "@/components/header/menu";
import MainBlocks from "@/components/Main";
import AnimationsBlock from "@/components/Animations";
import FooterBlock from "@/components/Footer";
import AOS from 'aos';
import LoaderBlock from "@/components/Loader";

export default {
  name: 'App',
  components: {
    LoaderBlock,
    FooterBlock,
    AnimationsBlock,
    MainBlocks,
    HeadMenu
  },
  data(){
    return {
      lastScroll: 0,
      loader: true
    }
  },
  methods: {
    handleScroll(){
      let headMenuItems = document.getElementById('header-menu').querySelectorAll('a'),
          headerMenu = [].slice.call(headMenuItems, 0),
          pointer = this,
          currentScroll = document.documentElement.scrollTop || document.body.scrollTop

      if (currentScroll > 0 && pointer.lastScroll <= currentScroll) {
        pointer.lastScroll = currentScroll;
        headerMenu = headerMenu.reverse()
      } else {
        pointer.lastScroll = currentScroll;
      }

      headMenuItems.forEach(function (hItem){
        hItem.classList.remove('active')
      })

      for (let item of headerMenu) {
        let sectionId = item.getAttribute('href'),
            element = document.getElementById(sectionId.replace('#', ''))

        if (element != null && pointer.isHidden(element)){
          item.classList.add('active')
          break
        }
      }

    },

    isHidden(el) {
      let top = el.offsetTop,
          left = el.offsetLeft,
          width = el.offsetWidth,
          height = el.offsetHeight;

      while(el.offsetParent) {
        el = el.offsetParent;
        top += el.offsetTop;
        left += el.offsetLeft;
      }

      return (
          top < (window.pageYOffset + window.innerHeight) &&
          left < (window.pageXOffset + window.innerWidth) &&
          (top + height) > window.pageYOffset  &&
          (left + width) > window.pageXOffset
      );
    }
  },
  created: function () {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted: function () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    document.documentElement.style.overflow = 'hidden'
    AOS.init();
    let pointer = this
    pointer.$nextTick(function () {
      setTimeout(function (){
        pointer.loader = false
        document.documentElement.style.overflow = 'auto'
      }, 1500);
    })
  }
}
</script>

<style lang="scss">

.vertical-align {
  display: flex;
  align-items: center;
}
.green-btn{
  transition: 0.5s;
  background: #0fb;
  display: block;
  line-height: 3rem;
  color: #303030;
  text-align: center;
  text-decoration: none;
  border-radius: 2rem;
  font-weight: 600;
  &:hover{
    transition: 0.5s;
    background: #0ff;
    color: #303030;
  }
}
#app{
  font-family: Montserrat, "sans-serif";
  min-height: 100vh;
  font-size: 14px;
  background: #262626;
}
html{
  background: #262626;
}
body{
  overflow: hidden;
  min-width: 320px;
}
</style>
