<template>
  <header id="home">
    <div class="header-menu">
      <div class="container">
        <div class="row vertical-align">
          <div class="logo col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
            <a href="/#home">
              <img src="@/assets/MT.png" alt="logo">
            </a>
          </div>
          <div class="col-md-6 col-sm-6 col-6 burger-block">
            <div @click="showMenu" :class="burgerActive ? 'header-burger active' : 'header-burger'">
              <span></span>
            </div>
          </div>
          <ul v-click-outside="hideMenu" id="header-menu" :class="menuActive ? 'menu active col-xl-8 col-lg-8 col-md-12 col-sm-6 col-12' : 'menu col-xl-8 col-lg-8 col-md-12 col-sm-6 col-12' ">
            <li>
              <a @click="showMenu" class="active" href="#home">Home</a>
            </li>
            <li>
              <a @click="showMenu" href="#about">About</a>
            </li>
            <li>
              <a @click="showMenu" href="#portfolio">Portfolio</a>
            </li>
            <li>
              <a @click="showMenu" href="#contact">Contact Me</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="about-me-short">
      <div class="container">
        <div class="row vertical-align">
          <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="left-text" data-aos-once="true" data-aos="slide-up" data-aos-duration="1500" data-aos-delay="1600">
              <div class="hi">
                HI THERE <img src="@/assets/img/icons/hi.svg" alt="hi"> I'M
              </div>
              <h1 class="title">
                Mykola<br>Tsurkan
              </h1>
              <div class="skills">
                <span>
                   Full Stack Web Developer
                </span>
              </div>
              <div class="description">
                I’m a professional PHP developer with front end development skills based in Canada.
              </div>
              <div class="hire-me">
                <a class="green-btn" href="#contact">
                  Hire Me
                </a>
              </div>
            </div>
          </div>
          <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12">
            <div class="right-banner" data-aos-once="true" data-aos="slide-left" data-aos-duration="1500" data-aos-delay="1600">

              <div class="pic-container">
                <img class="pic" src="@/assets/img/banners/me_transparent.png" alt="#">
                <img class="pic" src="@/assets/img/banners/me_transparent.png" alt="#">
                <img class="pic" src="@/assets/img/banners/me_transparent.png" alt="#">
                <div class="pic-caption">
                  <a href="#about" class="talktext">
                    <p>
                      Hi, i'm Mykola =D
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
export default {
  name: "HeadMenu",
  data(){
    return{
      burgerActive: false,
      menuActive: false
    }
  },
  methods: {
    showMenu(){
      this.burgerActive = !this.burgerActive
      this.menuActive = !this.menuActive
    },
    hideMenu(e){
      if (this.menuActive === true && e.target.classList.contains('header-burger') !== true) {
        this.burgerActive = false
        this.menuActive = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@700&display=swap');

header {
  background: #212121;
  .burger-block{
    display: none;
    .header-burger{
      cursor: pointer;
      margin-left: auto;
      position: relative;
      width: 30px;
      height: 20px;
      z-index: 3;
      span {
        position: absolute;
        background-color: #fff;
        left: 0;
        width: 100%;
        height: 2px;
        top: 9px;
        transition: all 0.3s ease 0s;
      }
      &:before,
      &:after {
        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        transition: all 0.3s ease 0s;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      &.active{
        span {
          transform: scale(0);
        }
        &:before {
          transform: rotate(45deg);
          top: 9px;
        }
        &:after {
          transform: rotate(-45deg);
          bottom: 9px;
        }
      }
    }
  }
  .header-menu{
    padding: 15px 0;
    background: rgba(33, 33, 33, 0.7);
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 10;
  }
  .logo{
    a{
      img{
        transition: 0.5s;
        max-width: 60px;
      }
      &:hover{
        img{
          transition: 0.5s;
          transform: scale(1.2);
        }
      }
    }
  }
  ul {
    padding-left: 0;
    margin: 0;
    &.menu {
      text-align: right;
      li {
        list-style: none;
        display: inline-block;
        vertical-align: top;
        margin-right: 45px;

        &:last-child {
          margin-right: 0;
        }

        a {
          transition: 0.3s;
          color: #ffffff;
          text-decoration: none;
          font-weight: 500;
          font-size: 1.2rem;
          &.active, &:hover{
            transition: 0.3s;
            color: #0ff;
          }
        }
      }
    }
  }
  .about-me-short{
    padding: 12rem 0;

    @media (max-width: 1200px) {
      padding: 9rem 0 3rem 0;
    }

    .left-text{
      color: #ffffff;
      position: relative;
      z-index: 1;
      .hi{
        text-transform: uppercase;
        font-size: 1.1rem;
        padding-bottom: 1.5rem;
        //@media (max-width: 1200px) {
        //  font-size: 0.8rem;
        //}
      }
      h1{
        &.title{
          font-family: "Cinzel Decorative", sans-serif;
          font-size: 4.2rem;
          font-weight: bold;
          padding-bottom: 1.5rem;
          margin-bottom: 0;
          @media (max-width: 1200px) {
            font-size: 3rem;
          }
        }
      }
      .skills{
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: bold;
        color: #0fb;
        padding-bottom: 1.5rem;
        span{
          display: inline-block;
          vertical-align: top;
        }
        &:after{
          content: ' ';
          display: inline-block;
          vertical-align: top;
          background-image: url('~@/assets/img/icons/mac-man.png');
          background-size: 24px auto;
          width: 24px;
          height: 24px;
          margin-left: 5px;
        }
      }
      .description{
        font-size: 1rem;
        padding-bottom: 1.5rem;
      }
      .hire-me{
        a{
          width: 10.7rem;
          height: 3rem;
          @media (max-width: 1200px) {
            font-size: 0.9rem;
            width: 8rem;
            height: 2.5rem;
            line-height: 2.5rem;
          }
        }

      }
    }
    .right-banner{
      text-align: right;
      position: relative;
      height: 500px;
      .pic-container{
        position: absolute;
        right: 0;
        width: 500px;
        height: 500px;
        @media (max-width: 1200px) {
          width: 450px;
          height: 450px;
          .pic{
            max-width: 450px;
          }
        }

        &:hover .pic:nth-child(2) {
          transform: rotate(7deg);
          transition: all 0.3s ease;
        }
        &:hover .pic:nth-child(3) {
          transform: rotate(-5deg);
        }
        &:hover .pic-caption {
          //right: 0;
          //left: auto;
          //bottom: 0;
          opacity: 1;
          z-index: 10;
          transform: translatex(160px) translatey(-70px);
          //top: 31%;
          //height: 40px;
          visibility: visible;
          -webkit-animation: diagonal 0.5s ease-in;
          -moz-animation: diagonal 0.5s ease-in;
          -o-animation: diagonal 0.5s ease-in;
          animation: diagonal 0.5s ease-in;
        }
        .pic-caption {
          display: inline-block;
          padding: 10px 15px;
          font-size: 12px;
          position: absolute;
          background: #0fb;
          z-index: 2;
          right: auto;
          left: 180px;
          top: 45%;
          width: 135px;
          height: 40px;
          border-radius: 3px;
          transition: all 0.5s ease;
          opacity: 0;
          visibility: hidden;
          &:after{
            content: ' ';
            position: absolute;
            width: 0;
            height: 0;
            left: -20px;
            right: auto;
            top: 0;
            bottom: auto;
            border: 22px solid;
            border-color: #0fb transparent transparent transparent;
          }
          a{
            &.talktext{
              display: block;
              color: #303030;
              text-decoration: none;
            }
          }
        }
        .pic {
          position: absolute;
          transition: all 0.2s ease;
          backface-visibility:hidden;
          right: 0;
          &:nth-child(1) {
            z-index: 3;
          }
          &:nth-child(2) {
            z-index: 1;
          }
          &:nth-child(3) {
            z-index: 2;
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .about-me-short{
      .left-text{
        text-align: center;
        .hire-me{
          a{
            margin: 0 auto;
          }
        }
      }
      .right-banner{
        .pic-container{
          left: 0;
          right: 0;
          margin: 3rem auto 0 auto;
        }
      }
    }
    .logo{
      a{
        position: relative;
        z-index: 12;
      }
    }
    .burger-block{
      display: initial;
      .header-burger{
        z-index: 12;
      }
    }
    ul{
      &.menu{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: auto;
        z-index: 11;
        opacity: 0;
        visibility: hidden;
        transition: 0.3s;
        background: #212121;
        padding: 2rem 0;
        li{
          display: block;
          margin-right: 0;
          text-align: center;
          a{
            display: block;
            padding: 1rem 0;
          }
        }
        &.active{
          opacity: 1;
          visibility: visible;
          transition: 0.3s;
        }
      }

    }
    @media (max-width: 768px) {

      .header-menu{
        padding: 10px 0;
      }
      .about-me-short{
        padding: 7rem 0 3rem 0;
        .left-text{
          .hi{
            font-size: 1rem;
            img{
              max-width: 15px;
            }
          }
          h1{
            &.title{
              font-size: 2.5rem;
            }
          }
          .skills{
            font-size: 1rem;
          }
        }
      }
      .logo{
        a{
          img{
            max-width: 50px;
          }
        }
      }

      @media (max-width: 550px) {
        ul{
          &.menu{
            li{
              a{
                font-size: 1rem;
              }
            }
          }
        }
        .logo{
          a{
            margin-left: 10px;
          }
        }
        .burger-block{
          .header-burger{
            margin-right: 10px;
          }
        }
        .about-me-short{
          .right-banner{
            height: 360px;
            .pic-container{
              width: 300px;
              height: 300px;
              .pic-caption{
                left: 75px;
                font-size: 10px;
                width: 120px;
                height: 35px;
              }

              .pic{
                width: 300px;
                height: 300px;
              }
            }
          }
          .left-text{
            .description{
              font-size: 0.9rem;
              padding: 0 1.5rem 2rem 1.5rem;
            }
            .skills{
              font-size: 0.9rem;
            }
            .hi{
              font-size: 0.9rem;
            }
            h1{
              &.title{
                font-size: 2.1rem;
              }
            }
          }
        }

      }

    }
  }

}

@-webkit-keyframes diagonal {
  0%       { transform: translatex(20px) translatey(20px); }
  100%     { transform: translatex(160px) translatey(-70px); }
}

@-moz-keyframes diagonal {
  0%       { transform: translatex(20px) translatey(20px); }
  100%     { transform: translatex(160px) translatey(-70px); }
}

@-o-keyframes diagonal {
  0%       { transform: translatex(20px) translatey(20px); }
  100%     { transform: translatex(160px) translatey(-70px); }
}

@keyframes diagonal {
  0%       { transform: translatex(20px) translatey(20px); }
  100%     { transform: translatex(160px) translatey(-70px); }
}
</style>