<template>
  <div class="animations">
    <div class="js">
      <h1 class="jt --debug">
        <span class="jt__row">
          <span class="jt__text">JS</span>
        </span>
              <span class="jt__row jt__row--sibling" aria-hidden="true">
          <span class="jt__text">JS</span>
        </span>
              <span class="jt__row jt__row--sibling" aria-hidden="true">
          <span class="jt__text">JS</span>
        </span>
              <span class="jt__row jt__row--sibling" aria-hidden="true">
          <span class="jt__text">JS</span>
        </span>
      </h1>
    </div>
    <div class="php">
      <h1 class="jt --debug">
        <span class="jt__row">
          <span class="jt__text">PHP</span>
        </span>
              <span class="jt__row jt__row--sibling" aria-hidden="true">
          <span class="jt__text">PHP</span>
        </span>
              <span class="jt__row jt__row--sibling" aria-hidden="true">
          <span class="jt__text">PHP</span>
        </span>
              <span class="jt__row jt__row--sibling" aria-hidden="true">
          <span class="jt__text">PHP</span>
        </span>
      </h1>
    </div>
    <div class="laravel">
        <div class="content">
          Laravel
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnimationsBlock"
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Titan+One&display=swap");

.animations{
  .php{
    transition: 0.3s;
    display: inline-block;
    position: fixed;
    animation: movePhp 20s 1 normal both;
    animation-iteration-count: infinite;
    z-index: 0;
    .jt {
      color: #0051ff;
      text-shadow: 0 0 10px #0051ff;
    }
    .jt__row, .jt__text {
      color: #0051ff;
    }
    .jt__text{
      animation: moveIn 4s 0s cubic-bezier(.36,0,.06,1) alternate infinite ;
    }
  }

  .laravel{
    z-index: 0;
    opacity: 0.2;
    transition: 0.3s;
    display: inline-block;
    position: fixed;
    animation: moveLaravel 25s 1 normal both;
    animation-iteration-count: infinite;
    .content{
      font-family: "Staatliches", sans-serif;
      font-size: 6rem;
      text-transform: uppercase;
      background: linear-gradient(90deg, #ffa600, #f9332b, #11ff00);
      letter-spacing: 5px;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      background-repeat: no-repeat;
      background-size: 80%;
      animation: shine 3s linear infinite;
      position: relative;
    }
  }

}
.js{
  z-index: 0;
  transition: 0.3s;
  display: inline-block;
  position: fixed;
  animation: move 15s 1 normal both;
  animation-iteration-count: infinite;
}

.jt {
  transition: 0.3s;
  color: tomato;
  position: relative;
  font-size: 6rem;
  font-family: 'Staatliches', sans-serif;
  text-transform: uppercase;
  font-display: swap;
  text-shadow: 0 0 10px tomato;
  opacity: 0.3;
}

.jt__row {
  display: block;
  color: tomato;
}
.jt__row:nth-child(1) {
  clip-path: polygon(-10% 75%, 110% 75%, 110% 110%, -10% 110%);
}
.jt__row:nth-child(2) {
  clip-path: polygon(-10% 50%, 110% 50%, 110% 75.3%, -10% 75.3%);
}
.jt__row:nth-child(3) {
  clip-path: polygon(-10% 25%, 110% 25%, 110% 50.3%, -10% 50.3%);
}
.jt__row:nth-child(4) {
  clip-path: polygon(-10% 0%, 110% 0%, 110% 25.3%, -10% 25.3%);
}

.jt__row.jt__row--sibling {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  witdh: 800px;
}

.jt__text {
  display: block;
  transform-origin: bottom left;
  animation: moveIn 2s 0s cubic-bezier(.36,0,.06,1) alternate infinite ;
  color: tomato;
}
.jt__row:nth-child(1) .jt__text {
  transform: translateY(-0.1em);
}
.jt__row:nth-child(2) .jt__text {
  transform: translateY(-0.3em) scaleY(1.1);
}
.jt__row:nth-child(3) .jt__text {
  transform: translateY(-0.5em) scaleY(1.2) ;
}
.jt__row:nth-child(4) .jt__text {
  transform: translateY(-0.7em) scaleY(1.3) ;
}
.jt__row:nth-child(5) .jt__text {
  transform: translateY(-0.9em) scaleY(1.4) ;
}
.jt__row:nth-child(6) .jt__text {
  transform: translateY(-1.1em) scaleY(1.5) ;
}

@keyframes moveIn {
  50%, 100% {
    transform: translateY(0em)
  }
  0%   {
    opacity: 0;
    filter: blur(10px);

  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}
.debug .jt__row:nth-child(even) {
  color: black;
  background: white;
}
.debug .jt__row:nth-child(odd) {
  color: white;
  background: black;
}
@keyframes move {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  20% {
    transform: translateX(500px);
  }

  35% {
    transform: translateY(700px);
  }

  50% {
    transform: translateX(1000px);
  }

  80% {
    transform: translate(1200px, 800px);
  }

  100% {
    transform: translate(1200px, 200px);
  }
}
@keyframes movePhp {
  0% {
    transform: translateX(80vw);
    opacity: 0;
  }

  20% {
    transform: translateX(50vw);
  }

  35% {
    transform: translateY(10vh);
  }

  50% {
    transform: translateX(80vw);
  }

  80% {
    transform: translate(30vw, 60vh);
  }

  100% {
    transform: translate(75vw, 5vh);
  }
}

@keyframes moveLaravel {
  0% {
    transform: translateX(30vw);
    opacity: 0;
  }

  20% {
    transform: translate(60vw, 30vh);
  }

  35% {
    transform: translate(10vw, 70vh);
  }

  50% {
    transform: translate(40vw, 17vh);
  }

  80% {
    transform: translate(10vw, 39vh);
  }

  100% {
    transform: translate(50vw, 80vh);
  }
}

@keyframes shine {
  0% {
    background-position-x: -500%;
  }
  100% {
    background-position-x: 500%;
  }
}
</style>