import { createApp } from 'vue'
import App from './App.vue'
import { BootstrapVue3 } from 'bootstrap-vue-3'
import vClickOutside from "click-outside-vue3"
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'aos/dist/aos.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const Vue = createApp(App)
Vue.use(BootstrapVue3)
Vue.use(vClickOutside)
Vue.use(VueAxios, axios)
Vue.mount('#app')
