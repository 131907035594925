<template>
  <div class="modals">
    <div class="modal fade" ref="morePortInfo" id="morePortInfo" tabindex="-1" role="dialog" aria-labelledby="tributeTitle">
      <div class="modal-dialog wide" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" data-bs-dismiss="modal" aria-label="Close">
              <img src="@/assets/img/close.svg" alt="close">
            </button>
            <h3 class="modal-title text-center">Project Details</h3>
          </div><!-- /.modal-header -->
          <div class="modal-body">
            <div class="row">
              <div class="col-xs-12 bg-star-g">
                <img v-if="typeof projectData.logo !== 'undefined'" class="img-responsive center-block hero" :src="require(`@/assets/img/portfolio/bg/${projectData.logo}`)" alt="Project logo">
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 name-block text-center">
                <h2>{{ projectData.name ?? '' }}</h2>
                <h4>{{ projectData.work ?? '' }}</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 col-lg-offset-0 col-xl-6 col-xl-offset-0 col-12">
                <div class="full-content" v-html="projectData.body ?? ''"></div>
              </div>
              <div class="col-lg-12 col-lg-offset-0 col-xl-6 col-lg-offset-0 md-center col-12 tech-block">
                <p><strong>Technologies Used:</strong></p>
                <p v-if="typeof projectData.technologies !== 'undefined' && projectData.technologies.length" class="mb-30 technologies">
                  <span v-for="tech in projectData.technologies" :key="tech.name" class="label tag">{{ tech.name }}</span>
                </p>
                <div class="col-12 mb-3">
                  <a class="green-outline-btn" rel="nofollow" :href="projectData.link ?? ''" target="_blank">Visit Live Site</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal HTML -->
    <div :class="isModalSuccess ? 'confirm-modal-alert show' : 'confirm-modal-alert'">
      <div class="text-block">
        <h2>Awesome!</h2>
        <p class="text-center">I will reply to you shortly.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalsBlock",
  props: {
    projectData: Object,
    isModalSuccess: Boolean
  }
}
</script>

<style lang="scss" scoped>
  .modal-dialog{
    max-width: 1320px;
  }
  .modal-title{
    width: 100%;
    text-align: center;
  }
  .modal-header{
    position: relative;
    button{
      position: absolute;
      right: 0.8rem;
      top: 0;
      bottom: 0;
      background: transparent;
      outline: none;
      border: none;
    }
  }
  .modal{
    .modal-body{
      padding: 0 0.75rem;
      .technologies{
        .label{
          display: inline-block;
          background: #4e5e71;
          color: #ffffff;
          padding: 3px 8px;
          margin-right: 0.3rem;
          font-size: 0.8rem;
          margin-bottom: 5px;
        }
      }
      .bg-star-g{
        margin-bottom: 2rem;
        text-align: center;
        background: #29a598 url('~@/assets/img/bg.png') fixed;
        background-size: 60px;
        img{
          max-width: 500px;
          @media (max-width: 650px) {
            max-width: 100%;
          }
        }
      }
      a{
        &.green-outline-btn{
          display: inline-block;
          border: 2px solid #4e5e71;
          text-decoration: none;
          color: #4e5e71;
          padding: 8px 30px;
          border-radius: 0.3rem;
          font-weight: 400;
          transition: 0.3s;
          &:hover{
            transition: 0.3s;
            background: #4e5e71;
            color: #ffffff;
          }
        }
      }
    }
    .modal-content{
      h4{
        color: #9da7a6;
        font-size: 1rem;
      }
      .name-block{
        margin-bottom: 2rem;
      }
      .full-content{
        padding: 0 15px 20px 15px;
        @media (max-width: 650px) {
          font-size: 0.8rem;
        }
      }
      @media (max-width: 1200px) {
        .tech-block{
          text-align: center;
        }
      }
    }
  }
  .confirm-modal-alert{
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    width: 100%;
    height: 0;
    overflow: hidden;
    left: 0;
    top: 0;
    z-index: 11;
    color: #ffffff;
    text-align: center;
    box-sizing: content-box;
    background: rgb(33, 33, 33);
    .text-block{
      padding: 25px 0;
      h2{
        font-size: 1.5rem;
      }
      .text-center{
        font-size: 1rem;
        padding-top: 1rem;
      }
    }
    &.show{
      transition: 0.5s;
      opacity: 1;
      visibility: visible;
      height: auto;
    }
  }
</style>