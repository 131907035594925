<template>
  <footer data-aos-once="true" data-aos="zoom-up">
    <div class="container">
      <div class="row vertical-align">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 footer-logo">
          <a href="/#home">
            <img src="@/assets/MT.png" alt="logo">
          </a>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-center">
          <div class="copy">© Copyright 2023. All right reserved</div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
          <div class="socials">
            <div class="item">
              <a target="_blank" href="https://www.linkedin.com/in/mykola-tsurkan-1b3744148/">
                <img src="@/assets/img/icons/social/linkedin.svg" alt="#">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBlock"
}
</script>

<style lang="scss" scoped>
  footer {
    background: #212121;
    padding: 1.5rem 0;
    .footer-logo {
      img {
        max-width: 60px;
      }
    }
    .copy{
      color: #ffffff;
    }
    .socials{
      font-size: 0;
      text-align: right;
      .item{
        display: inline-block;
        vertical-align: middle;
      }
    }
    @media (max-width: 991px) {
      padding: 1rem 0;
      .copy{
        font-size: 0.8rem;
      }
      .footer-logo{
        img{
          max-width: 50px;
        }
      }
      @media (max-width: 550px) {
        text-align: center;
        .copy{
          padding-top: 1.5rem;
        }
        .socials{
          padding-top: 1.5rem;
          text-align: center;
        }

      }
    }
  }
</style>