<template>
  <modals-block :is-modal-success="isModalSuccess" :project-data="projectData"></modals-block>
  <overlay-block v-if="overlay"></overlay-block>
  <main id="nav-scroller">
    <section class="about-me" id="about">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mx-auto">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <div class="photo-block" data-aos-once="true" data-aos="fade-down-right" data-aos-duration="1500">
                  <img src="@/assets/img/banners/about.jpg" alt="about">
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="info-block" data-aos-once="true" data-aos="fade-up-left" data-aos-duration="1500">
                  <div class="ho-i-am subTitle">
                    <span>
                      Who I am
                    </span>
                  </div>
                  <h3>
                    About Me
                  </h3>
                  <div class="description">
                    <p>As a seasoned and multi-talented PHP developer, I bring a rare combination of technical proficiency and imaginative problem-solving to the table. With a passion for delivering outstanding user experiences and a portfolio of successful projects under my belt, I am ready to take on the next big challenge.</p>

                    <p>My expertise in PHP frameworks such as Laravel, Symfony, Yii2, and Codeigniter, along with my extensive experience with CMS platforms including OpenCart, Joomla, and WordPress, makes me a versatile player in the field of web development. But what sets me apart is my unrelenting pursuit of excellence and my unwavering commitment to delivering the best possible results for every project I undertake.</p>

                    <p>In a world where cookie-cutter solutions are all too common, I strive to bring a unique perspective and innovative approach to every project I work on. Whether I am faced with a complex problem or a simple request, I am always up for the challenge and eager to push the boundaries of what is possible.</p>
                  </div>
                  <a target="_blank" href="https://tsurkan.tech/CV.pdf" class="download-cv green-btn">
                    Download CV
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="portfolio" id="portfolio">
      <div class="col-xl-12 text-center top">
        <div class="my-project subTitle">
          <span>My Project</span>
        </div>
        <div class="title">
          My Portfolio
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-xl-11 col-lg-12 mx-auto">
            <div class="row items">

              <div v-for="portfolioItem in portfolioItems" :key="portfolioItem.name" class="col-xl-4 col-lg-4 col-md-6 col-sm-12 item" data-aos-once="true" data-aos="zoom-in" data-aos-delay="{{ portfolioItem.delay }}">
                <div class="content">
                  <div :style="{ backgroundImage: `url(${require('@/assets/img/portfolio/' + portfolioItem.image)})` }" class="background"></div>
                  <div class="p-card">
                    <div class="title">{{ portfolioItem.name }}</div>
                    <div class="description">
                      {{ portfolioItem.description }}
                      <div  class="technologies">
                        <div v-for="tech in portfolioItem.technologies" :key="tech.name" :class="`item ${tech.name}`">
                          {{ tech.name }}
                        </div>
                      </div>
                    </div>
                    <a data-bs-toggle="modal" data-bs-target="#morePortInfo" href="javascript:void(0)" @click="showMore(portfolioItem.name)" class="more">More</a>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="contact" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-xl-12 text-center top">
            <div class="subTitle">
              <span>Contact Me</span>
            </div>
            <div class="title">
              Get In Touch
            </div>
            <div class="description">
              Ready to work on your projects and bring your ideas<br> to life. I'm just a click away.
            </div>
          </div>

          <div class="order-xl-0 order-lg-0 order-md-0 col-xl-4 col-lg-4 col-md-3 col-sm-12 order-sm-1 order-1 contactItems">
            <div class="item" data-aos-once="true" data-aos="fade-up" data-aos-delay="200">
              <div class="circle">
                <img src="@/assets/img/icons/pointer.svg" alt="pointer">
              </div>
              <a target="_blank" href="https://maps.google.com/?q=Hamilton+Canada">Toronto, Canada</a>
            </div>

            <div class="item" data-aos-once="true" data-aos="fade-up" data-aos-delay="400">
              <div class="circle">
                <img src="@/assets/img/icons/mail.svg" alt="pointer">
              </div>
              <a href="mailto:mykola@tsurkan.tech">mykola@tsurkan.tech</a>
            </div>

            <div class="item" data-aos-once="true" data-aos="fade-up" data-aos-delay="600">
              <div class="circle">
                <img src="@/assets/img/icons/phone.svg" alt="pointer">
              </div>
              <a href="tel:+16477662739">+1 647-766-2739</a>
            </div>
          </div>

          <div class="order-xl-1 order-lg-1 order-md-1 col-xl-8 col-lg-8 col-md-9 col-sm-12 order-sm-0 order-0">
            <form v-on:submit.prevent="submitForm">
              <div class="form">
                <div class="input" data-aos-once="true" data-aos="fade-right" data-aos-delay="400">
                  <input v-model="form.name" type="text" name="name" placeholder="Name" required>
                </div>
                <div class="input" data-aos-once="true" data-aos="fade-left" data-aos-delay="600">
                  <input v-model="form.email" type="email" name="email" placeholder="Email" required>
                </div>
                <div class="input" data-aos-once="true" data-aos="fade-up" data-aos-delay="800">
                  <textarea v-model="form.message" name="message" placeholder="Enter your message" required></textarea>
                </div>
                <div class="input" data-aos-once="true" data-aos="fade-up" data-aos-delay="1000">
                  <input class="green-btn" type="submit" value="Submit">
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </section>
  </main>
</template>

<script>
import ModalsBlock from "@/components/Modals";
import OverlayBlock from "@/components/Overlay";


export default {
  components: {
    ModalsBlock,
    OverlayBlock
  },
  name: "MainBlocks",
  data(){
    return {
      isModalSuccess: false,
      form: {
        name: '',
        email: '',
        message: ''
      },
      projectData: {},
      overlay: false,
      portfolioItems: [
        {
          delay: 200,
          name: 'Work.ua',
          description: 'Work.ua is a highly esteemed job platform that serves as the hub for top companies in Ukraine to find and attract top talent.' +
              ' With a reputation for being the leading job site in the country, Work.ua takes pride in delivering a seamless and efficient experience for both employers and job seekers.',
          image: '1.jpg',
          logo: 'work.ua.png',
          work: 'Website functionality improvements',
          body: '<p>As a software developer at Work.ua, I made significant contributions to Ukraine\'s leading job site. My efforts were aimed at enhancing the user experience and increasing the effectiveness of the platform.</p>\n' +
              '<p><strong>My notable contributions include:</strong></p>\n' +
              '<p>1. Implementing Apple ID authorization, which resulted in a 5% increase in daily registrations.</p>\n' +
              '<p>2. Developing a comparison feature between Work.ua and its competitors, accessible from the admin panel, which proved to be a valuable tool for managers in attracting clients to the platform.</p>\n' +
              '<p>3. Enhancing the platform by displaying the number of users viewing a particular vacancy, leading to a 10% increase in responses to job postings.</p>\n' +
              '<p>4. Designing a mechanism for remote theme customization through the site\'s admin panel, enabling easy upload of resources for changing the theme of the application.</p> \n' +
              '<p>I take pride in my work and am confident that my contributions have helped establish Work.ua as the fastest, most convenient, and effective employment site in Ukraine.</p>',
          link: 'https://work.ua/en/',
          technologies: [
            {
              name: 'php'
            },
            {
              name: 'mysql'
            },
            {
              name: 'js'
            },
            {
              name: 'docker'
            },
            {
              name: 'css'
            },
            {
              name: 'html'
            },
            {
              name: 'manticore'
            },
            {
              name: 'redis'
            }
          ]
        },
        {
          delay: 400,
          name: 'Tekman',
          description: 'Tekman Shop is a premier provider of professional-grade tools and equipment, catering to the needs of private workshops, production facilities, construction sites, and other industrial settings. As the official online store, Tekman Shop offers a comprehensive and convenient source for all your tool and equipment requirements.',
          image: '2.jpg',
          logo: 'tekman.png',
          work: 'Full Stack development',
          body: '<p>As a professional e-commerce solution provider, I am proud to present TEKMAN SHOP - an official online platform that caters to the needs of workshops, production facilities, construction sites, and other similar environments. The store features a highly modular structure, leveraging the Laravel Modules framework (https://github.com/nWidart/laravel-modules), to ensure seamless scalability and customization.</p>' +
              '<p>In order to serve a wider audience, the platform supports multiple languages, providing a truly inclusive shopping experience. Moreover, the integration of APIs such as Novaposhta, Ukrposhta, Liqpay, and Google Geocoding API ensures that the platform is well-equipped to handle shipping, payment processing, and geolocation services.</p>' +
              '<p>For B2B clients, the platform offers an integrated solution for import of categories, brands, products, and their attributes directly from the B2B portal, streamlining the procurement process and optimizing operations for maximum efficiency.</p>',
          link: 'https://tekmanshop.com.ua/',
          technologies: [
            {
              name: 'laravel'
            },
            {
              name: 'php'
            },
            {
              name: 'mysql',
            },
            {
              name: 'js'
            },
            {
              name: 'jquery'
            },
            {
              name: 'css'
            },
            {
              name: 'html'
            }
          ]
        },
        {
          delay: 600,
          name: 'Lscjax',
          description: 'LSY JAX is a community of young adults that is affiliated with the Living Stream Church in Jacksonville, FL. The ministry seeks to empower its members by promoting spiritual growth and strengthening their faith through a deeper relationship with Jesus Christ.',
          image: '3.jpg',
          logo: 'lscjax.png',
          work: 'Website functionality improvements',
          body: '<p>As a web developer, I have worked with LSY Jax, a community of young adults and a ministry of Living Stream Church located in Jacksonville, FL, to support their mission of enhancing spiritual growth and fortitude in Christ. In this role, I was tasked with improving the organization\'s online functionality and user experience, specifically in the areas of broadcast notifications, donations management, and website adaptability.</p>' +
              '<p>I successfully implemented a more efficient notification system for broadcasts, both on the main screen and via email, to ensure timely and effective communication with members. I also set up and managed merchants to handle donations effectively and efficiently. Additionally, I improved the website\'s adaptability to different devices, resulting in a seamless user experience for all visitors.</p>',
          link: 'https://lscjax.org/',
          technologies: [
            {
              name: 'laravel'
            },
            {
              name: 'php'
            },
            {
              name: 'mysql'
            },
            {
              name: 'js'
            },
            {
              name: 'jquery'
            },
            {
              name: 'css'
            },
            {
              name: 'html'
            }
          ]
        },
        {
          delay: 800,
          name: 'Luxreplica',
          description: 'E-commerce platform for premium branded watch replicas, developed with Laravel. Features include efficient administration, robust admin panel, sales statistics, product import/export, SEO optimization, and advanced order notifications.',
          image: '4.jpg',
          logo: 'luxreplica.png',
          work: 'Full Stack development',
          body: '<p>Online store of branded watch replicas.</p>' +
              '<p>As a highly experienced software developer, I am proud to present my e-commerce project, an online store specializing in premium branded watch replicas.</p>' +
              '<p>This platform has been expertly crafted using the Laravel framework and boasts a modular structure, offering efficient administration and content management capabilities. The robust admin panel provides insightful sales statistics, the ability to import and export products via CSV, and comprehensive SEO optimization tools, ensuring optimal visibility and user engagement.</p>' +
              '<p>In addition, the platform integrates advanced order notification systems through Telegram, SMS, and email, delivering real-time updates and enhancing customer satisfaction. This project is a testament to my commitment to delivering exceptional e-commerce solutions.</p>',
          link: 'https://luxreplica.store/',
          technologies: [
            {
              name: 'laravel'
            },
            {
              name: 'php'
            },
            {
              name: 'mysql'
            },
            {
              name: 'js'
            },
            {
              name: 'jquery'
            },
            {
              name: 'css'
            },
            {
              name: 'html'
            }
          ]
        },
        {
          delay: 1000,
          name: 'Follower24',
          description: 'Follower24 is a social media platform offering services for growth and engagement. Features include targeted likes/followers, improved payment process, and optimized user experience.',
          logo: 'follower24.png',
          work: 'Website functionality improvements',
          body: '<p>As a web developer, I was pleased to contribute to the improvement of Follower24\'s platform. The team at Follower24 is dedicated to providing valuable resources and services to support their clients\' social media growth.</p>' +
              '<p><strong>To ensure an exceptional user experience, I was involved in the following projects:</strong></p>' +
              '<p>1. Troubleshooting and optimization of product pages to provide a seamless navigation experience.</p>' +
              '<p>2. Implementation of a feature that allows clients to purchase likes and followers based on specific geographic locations. </p>' +
              '<p>3. Integration of Datatrans merchant and resolution of compatibility issues with Apple Pay to enhance the payment process.</p>' +
              '<p>Through these efforts, I am proud to have played a role in helping Follower24 achieve their mission of supporting their clients\' social media success.</p>',
          link: 'https://www.follower24.de/',
          image: '5.jpg',
          technologies: [
            {
              name: 'yii2'
            },
            {
              name: 'php'
            },
            {
              name: 'mysql'
            },
            {
              name: 'js'
            },
            {
              name: 'jquery'
            },
            {
              name: 'css'
            },
            {
              name: 'html'
            }
          ]
        },
        {
          delay: 1200,
          name: 'HannahManufacturing',
          description: 'A system for turning ideas into manufactured products in the USA, with personalized user accounts, an administrative panel, a tailored registration page, and mobile optimization.',
          image: '6.jpg',
          logo: 'hanna.png',
          work: 'Website functionality improvements',
          body: '<p>As a seasoned software developer, I am pleased to offer my expertise in the creation of a sophisticated system aimed at facilitating the transformation of innovative ideas into viable products that can be manufactured in the United States.</p>' +
              '<p><strong>To enhance the system\'s functionality, I have implemented a number of key improvements, including:</strong></p>' +
              '<p>1. The development of personal user accounts that cater to different roles, such as Designers, Market Researchers, and Inventors. This allows for a more customized experience and efficient collaboration between users. </p>' +
              '<p>2. An administrative panel with a dedicated section for managing users and their ideas, providing enhanced organization and control for the system administrators. </p>' +
              '<p>3. A user registration page tailored to each unique role, streamlining the process of adding new users to the system.</p>' +
              '<p>4. Optimization of all pages for use on mobile devices, ensuring a seamless and convenient experience for users on-the-go.</p>' +
              '<p>These enhancements demonstrate my commitment to delivering high-quality, efficient, and user-friendly solutions that meet the evolving needs of businesses and individuals.</p>',
          link: 'https://hannahmanufacturing.com/',
          technologies: [
            {
              name: 'wordpress'
            },
            {
              name: 'php'
            },
            {
              name: 'mysql'
            },
            {
              name: 'js'
            },
            {
              name: 'jquery'
            },
            {
              name: 'css'
            },
            {
              name: 'html'
            }
          ]
        }
      ]
    }
  },
  methods: {
    showMore(portfolioItemName){
      let pointer = this
      pointer.overlay = true

      this.projectData = this.portfolioItems.find((item) => item.name === portfolioItemName)
      setTimeout(function (){
        pointer.overlay = false
      }, 600)
    },
    showModal() {
      let pointer = this
      pointer.isModalSuccess = true
      setTimeout(function (){
        pointer.isModalSuccess = false
      }, 2000)
    },
    clearForm(){
      this.form = {
        name: '',
        email: '',
        message: ''
      }
    },
    submitForm(){
      let pointer = this

      pointer.overlay = true
      pointer.axios.post('https://tsurkan.tech/send.php', this.form)
          .then(() => {
            pointer.clearForm()
            pointer.overlay = false
            pointer.showModal()
          })
          .catch(() => {
            window.location.reload();
          });
    }
  }
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');

  @mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
  }

  .subTitle{
    color: #0fb;
    font-weight: 600;
    padding-bottom: 0.5rem;
    span{
      display: inline-block;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
    &:after{
      content: " ";
      display: inline-block;
      vertical-align: middle;
      width: 3.5rem;
      height: 0.2rem;
      background: #00ffbb;
      border-radius: 0.3rem;
    }
  }
  main{
    section{
      &.about-me{
        padding: 10rem 0;
        color: #ffffff;
        @media (max-width: 1200px) {
          padding: 8rem 0;
        }
        .photo-block{
          display: inline-block;
          position: relative;
          img{
            max-width: 100%;
          }
          &:after{
            content: " ";
            position: absolute;
            top: -1.5rem;
            left: -1.5rem;
            border-top: 9px solid #0fb;
            border-left: 9px solid #0fb;
            height: 95%;
            width: 95%;
          }
        }
        .info-block{
          padding-left: 30px;
          h3{
            font-size: 1.5rem;
            padding-bottom: 0.8rem;
          }
          .description{
            font-size: 0.9rem;
            padding-bottom: 0.8rem;
            line-height: normal;
            p {
              margin-bottom: 0.6rem;
            }
          }
          .download-cv{
            width: 12rem;
          }
        }

      }
      &.contact{
        padding-bottom: 10rem;
        @media (max-width: 1200px) {
          padding-bottom: 5rem;
        }
        .top{
          padding-bottom: 2.5rem;
          .subTitle{
            padding-bottom: 1rem;
          }
          .title{
            color: #ffffff;
            font-size: 1.5rem;
            padding-bottom: 1rem;
          }
          .description{
            color: #ffffff;
          }
        }
        .contactItems{
          .item{
            margin-bottom: 2rem;
            color: #ffffff;
            font-size: 1.05rem;
            .circle{
              display: inline-flex;
              vertical-align: middle;
              width: 3rem;
              height: 3rem;
              background: #0fb;
              border-radius: 3rem;
              align-items: center;
              justify-content: center;
              margin-right: 1rem;
              img{
                max-height: 20px;
              }
            }
            span, a{
              display: inline-flex;
              vertical-align: middle;
            }
            a{
              transition: 0.3s;
              text-decoration: none;
              color: #ffffff;
              &:hover{
                transition: 0.3s;
                color: #0ff;
              }
            }
          }
        }
        .form{
          position: relative;
          z-index: 1;
          .input{
            margin-bottom: 1.5rem;
            input[type="text"], input[type="email"], textarea{
              transition: 0.3s;
              width: 100%;
              border-radius: 1rem;
              background: transparent;
              outline: 1px solid #ffffff;
              border: none;
              padding: 10px 15px;
              color: #ffffff;
              font-size: 1.02rem;
              &:active, &:focus{
                transition: 0.3s;
                outline: 1px solid #0fb;
              }
              @include placeholder {
                color: #ffffff;
              }
            }
            input[type="submit"]{
              border: none;
              outline: none;
              width: 10rem;
            }
            textarea{
              min-height: 10rem;
            }
          }
        }
      }
      &.portfolio{
        padding-bottom: 10rem;
        @media (max-width: 1200px) {
          padding-bottom: 6rem;
        }
        .top{
          padding-bottom: 2.5rem;
          .my-project{
            padding-bottom: 1rem;
          }
          .title{
            color: #ffffff;
            font-size: 1.5rem;
          }
        }
        .items{
          .item{
            margin-bottom: 1.9rem;
            .content{
              box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
              width: 100%;
              height: 33rem;
              position: relative;
              overflow: hidden;
              border-radius: 0.35rem;
              transition: all 1s ease;
              .p-card{
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 1rem 10px;
                position: relative;
                opacity: 0;
                visibility: hidden;
                transition: all .3s ease;
                color: #fff;
                .title{
                  font-size: 1.2rem;
                  font-weight: 600;
                  padding-bottom: 1.2rem;
                }
                .description{
                  padding-bottom: 1.2rem;
                  .technologies{
                    padding-top: 1rem;
                    font-size: 0;
                    .item{
                      display: inline-block;
                      vertical-align: middle;
                      font-size: 0.9rem;
                      margin-right: 0.5rem;
                      font-family: 'Permanent Marker', cursive;
                      margin-bottom: 0.1rem;
                      &.php{
                        color: #2c4ab9;
                      }
                      &.mysql{
                        color: #f39210;
                      }
                      &.html{
                        color: #00ffbb;
                      }
                      &.docker{
                        color: #2697ed;
                      }
                      &.manticore{
                        color: #9fc755;
                      }
                      &.redis{
                        color: #fa9992;
                      }
                      &.laravel{
                        color: #e3372b;
                      }
                      &.jquery{
                        color: #90a1ef;
                      }
                      &.wordpress{
                        color: #206f92;
                      }
                      &.yii2{
                        color: #49759f;
                      }
                      &:last-child{
                        margin-right: 0;
                      }
                    }
                  }
                }
                a{
                  &.more{
                    display: inline-block;
                    text-decoration: none;
                    font-weight: 500;
                    color: #fff;
                    padding: 0.8rem 2rem;
                    margin-top: 1rem;
                    background: #2371ae;
                    border-radius: 5rem;
                    background: linear-gradient(90deg,#bb47d4 0,#2371ae);
                  }
                }
              }

              &:hover{
                .p-card{
                  opacity: 1;
                  visibility: visible;
                  transition: all .6s ease;
                  transition-delay: .2s;
                  z-index: 3;
                }
                .background{
                  opacity: 0;
                  transition: all .5s ease;
                }
                &:before{
                  transform: skew(30deg) translateX(-125%);
                  //background: linear-gradient(90deg, #bb47d4 0,#2371ae);
                  background: linear-gradient(90deg, #bb47d4 0, #25b28d);
                  opacity: 0.7;
                }
                &:after{
                  transform: skew(30deg) translateX(125%);
                  //background: linear-gradient(90deg, #bb47d4 0, #2371ae);
                  background: linear-gradient(90deg, #bb47d4 0, #25b28d);
                  opacity: 0.7;
                }
              }
              &:before{
                content: ' ';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                //background: linear-gradient(90deg,#2371ae 0,#bb47d4);
                background: linear-gradient(90deg, #25b28d 0,#bb47d4);
                opacity: .9;
                transition: all .6s ease;
                z-index: 2;
                transform: skew(30deg) translateX(125%);
              }
              &:after{
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg,#2371ae 0,#bb47d4);
                opacity: .9;
                transition: all .6s ease;
                z-index: 2;
                transform: skew(30deg) translateX(-125%);
            }
              .background{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 1;
                transition: all .5s ease-in;
                background-position: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                background-color: #0f0d16;
                object-fit: cover;
              }
            }
          }
        }
      }
    }
    @media (max-width: 991px) {
      section{
        &.about-me{
          padding: 5rem 0;

          .info-block{
            padding-left: 5px;
            .description{
              font-size: 0.8rem;
            }
          }
        }
        &.portfolio{
          padding-bottom: 5rem;
        }
        &.contact{
          .form{
            padding-left: 3rem;
            .input{
              margin-bottom: 1.5rem;
              input[type="text"], input[type="email"], textarea{
                padding: 10px 15px;
                color: #ffffff;
                font-size: 0.8rem;
                &:active, &:focus{
                  transition: 0.3s;
                  outline: 1px solid #0fb;
                }
                @include placeholder {
                  color: #ffffff;
                }
              }
              input[type="submit"]{
                border: none;
                outline: none;
                width: 6rem;
                height: 2rem;
                line-height: 2rem;
                font-size: 0.8rem;
              }
              textarea{
                min-height: 10rem;
              }
            }
          }
          .contactItems{
            .item{
              font-size: 0.8rem;
              white-space: nowrap;
              .circle{
                width: 1.8rem;
                height: 1.8rem;
                margin-right: 0.5rem;
                img{
                  max-height: 1rem;
                }
              }
            }
          }
        }
      }
      @media (max-width: 768px) {
        section{
          &.about-me{
            .info-block{
              text-align: center;
              .download-cv{
                margin: 0 auto;
              }
            }
            .photo-block{
              max-width: 400px;
              margin: 0 auto 2rem auto;
              display: block;
            }
          }
          &.portfolio{
            padding-bottom: 3rem;
            .items{
              .item{
                .content{
                  width: 80%;
                  height: 25rem;
                  min-width: 300px;
                  margin: 0 auto;
                  &:before{
                    transform: skew(30deg) translateX(110%);
                  }
                  &:after{
                    transform: skew(30deg) translateX(-110%);
                  }
                  &:hover{
                    &:before{
                      transform: skew(30deg) translateX(-110%);
                    }
                    &:after{
                      transform: skew(30deg) translateX(110%);
                    }
                  }
                }
              }
            }
          }
          &.contact{
            padding-bottom: 1rem;
            .form{
              padding-left: 0;
              padding-bottom: 3rem;
              .input{
                .green-btn{
                  margin: 0 auto;
                }
              }
            }
            .contactItems{
              font-size: 0;
              .item{
                text-align: center;
                .circle{
                  display: block;
                  line-height: 1.7rem;
                  margin: 0 auto 5px auto;
                }
                display: inline-block;
                width: 33%;
              }
            }
          }
          @media (max-width: 550px) {
            &.about-me{
              .photo-block{
                max-width: 80%;
                &:after{
                  border-top: 5px solid #0fb;
                  border-left: 5px solid #0fb;
                }
              }
            }
            &.contact{
              .contactItems{
                .item{
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
</style>